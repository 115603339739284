import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { UtilsService } from './utils.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private _utils: UtilsService,
    private toaster: ToastrService,
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = this._utils.tokenLocalStorage;
    const tenantId = this._utils.tenantIdLocalStorage;

    let requetClone = null;
    let request = null;

    if (token) {
      request = req.clone({
        headers: req.headers.append('Authorization', `Bearer ${token}`)
      });

      if (tenantId) {
        requetClone = request.clone({
          headers: request.headers.append('tenantid', `${tenantId}`)
        });
      }

      return next.handle(requetClone || request).pipe(
        tap(
          _ => { },
          err => {
            if (err.status === 403) {
              this.toaster.warning(err.error.error, 'Atenção');
              console.error(err.error.error);
              this.router.navigateByUrl('dashboard/management');
            }
          }
        )
      );
    } else {
      return next.handle(req.clone());
    }
  }
}

<ul class="navbar-nav">
  <li class="pr-1">
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <a class="navbar-icon" href="javascript:" ngbDropdownToggle data-toggle="dropdown" title="Selecionar entidade">
        <i class="fas fa-building"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
        <div class="pro-head" [style.background-color]="backgroundColor">
          <img src="assets/images/user/entity.png" class="img-radius" alt="User-Profile-Image" />
          <span>Lista de Contratantes</span>
        </div>
        <div class="my-3 text-center">
          <p class="font-weight-bold"> Contratante Selecionado: </p>
          <p> {{ entityName }} </p>
        </div>

        <div *ngFor="let entity of userEntities; let idx = index" class="my-2">
          <hr />
          <p class="pl-3 item-hover" id="item" (click)="setTenantId(entity.id, entity.name)">
            {{ idx + 1 }}. {{ entity.name }}
          </p>
        </div>

      </div>
    </div>
  </li>

  <li class="pr-1">
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <a class="navbar-icon" href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <i class="far fa-bell"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
        <div class="pro-head" [style.background-color]="backgroundColor">
          <img src="assets/images/user/notification-3.png" class="img-radius" alt="User-Profile-Image" />
          <span>Notificações</span>
        </div>
        <ul class="noti-body" style="background-color: #f4f7fa !important">
          <li class="notification" *ngFor="let notification of data; let idx = index">
            <a href="javascript:" (click)="loadPage(notification.status)">
              <div class="media">
                <i class="feather icon-bell"></i>
                <div class="media-body">
                  <p>{{ notification.message }}</p>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </li>
  <li>
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <a class="navbar-icon" href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <i class="far fa-user"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
        <div class="pro-head" [style.background-color]="backgroundColor">
          <img src="assets/images/user/avatar-4.jpg" class="img-radius" alt="User-Profile-Image" />
          <span>{{ userName }}</span>
          <a href="javascript:" class="dud-logout" title="Logout" (click)="fazerLogout()">
            <i class="feather icon-log-out"></i>
          </a>
        </div>
        <ul class="pro-body" style="background-color: #f4f7fa !important">
          <li>
            <a href="javascript:" class="dropdown-item" (click)="openPerfil()">
              <i class="feather icon-settings"></i> Configurações
            </a>
          </li>
          <li>
            <a href="javascript:" class="dropdown-item" (click)="openPerfil(true)">
              <i class="feather icon-user"></i> Perfil
            </a>
          </li>
          <li>
            <a href="javascript:" class="dropdown-item" (click)="openMessages()">
              <i class="feather icon-mail"></i> Minhas mensagens
            </a>
          </li>

        </ul>
      </div>
    </div>
  </li>
</ul>
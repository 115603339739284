import { Component, Input, OnInit } from '@angular/core';
import { NavigationItem } from '../../navigation';
import { Router } from '@angular/router';
import { NavigationItemInterface } from '../../navigation';
import { animate, style, transition, trigger } from '@angular/animations';
import { DattaConfig } from '../../../../../../app-config';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { FeatureService } from 'src/app/shared/services/features.service';

@Component({
  selector: 'app-nav-collapse',
  templateUrl: './nav-collapse.component.html',
  styleUrls: ['./nav-collapse.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)', display: 'block' }),
        animate('250ms ease-in', style({ transform: 'translateY(0%)' }))
      ]),
      transition(':leave', [
        animate('250ms ease-in', style({ transform: 'translateY(-100%)' }))
      ])
    ])
  ],
})
export class NavCollapseComponent implements OnInit {
  public visible: boolean;
  @Input() item: NavigationItemInterface;
  public dattaConfig: any;
  public themeLayout: string;
  featureActives = false;

  constructor(
    private router: Router,
    private themeService: ThemeService,
    private _featureService: FeatureService
  ) {
    this.visible = false;
    this.dattaConfig = DattaConfig.config;
    this.themeLayout = this.dattaConfig['layout'];
  }

  ngOnInit() {
    this._featureService.init().then(() => { //@audit FeatureFlag
      this.featureActives = this._featureService.get("featureActives");
      const title = this.featureActives ? "Ativos" : "Equipamentos";
      if (this.item?.id === "menu-equipamentos") {
        this.item.title = title;
        this.item.children[0].title = `Tipos de ${title}`;
        this.item.children[1].title = title;
      }
    });
  }

  navCollapse(e) {
    if (!this.item.disabled) {
      this.visible = !this.visible;

      let parent = e.target;
      if (this.themeLayout === 'vertical') {
        parent = parent.parentElement;
      }

      const sections = document.querySelectorAll('.pcoded-hasmenu');
      for (let i = 0; i < sections.length; i++) {
        if (sections[i] !== parent) {
          sections[i].classList.remove('pcoded-trigger');
        }
      }

      let first_parent = parent.parentElement;
      let pre_parent = parent.parentElement.parentElement;
      if (first_parent.classList.contains('pcoded-hasmenu')) {
        do {
          first_parent.classList.add('pcoded-trigger');
          // first_parent.parentElement.classList.toggle('pcoded-trigger');
          first_parent = first_parent.parentElement.parentElement.parentElement;
        } while (first_parent.classList.contains('pcoded-hasmenu'));
      } else if (pre_parent.classList.contains('pcoded-submenu')) {
        do {
          pre_parent.parentElement.classList.add('pcoded-trigger');
          pre_parent = pre_parent.parentElement.parentElement.parentElement;
        } while (pre_parent.classList.contains('pcoded-submenu'));
      }
      parent.classList.toggle('pcoded-trigger');
    }
  }

  get navClass() {
    return this.item.disabled ? "disabled" : "";
  }

  get title() {
    return this.item.disabled ? "Não é possível acessar esse item." : "pointer";
  }

  get backgroundColor() {
    return this.themeService.sessionSecondaryColor || this.themeService.defaultSecondaryColor;
  }

  get iconClass() {
    switch (this.item?.id) {
      case "menu-geral": return "secondStep";
      case "menu-colaboradores": return "thirdStep";
      default:
        return "";
    }
  }
}
